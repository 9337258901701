export enum GTMEvents {
  begin_checkout = 'begin_checkout',
  toggle_editing = 'click_payment_toggle_editing',
  service_type_selected_EC = 'funnel_EC_service_type',
  service_type_selected_FT = 'funnel_FT_service_type',
  funnel_EC_choose_expert = 'funnel_EC_choose_expert',
  funnel_FT_choose_expert = 'funnel_FT_choose_expert',
  funnel_FT_after_quote_choose_expert = 'funnel_FT_after_quote_choose_expert',
  funnel_FT_after_quote_choose_languages = 'funnel_FT_after_quote_choose_languages',
  wc_updated = 'user_engagement_wizard_wc_updated',
  ft_languages_select = 'funnel_FT_choose_languages',
  file_remove = 'user_engagement_wizard_file_remove',
  user_engagement_wizard_addon_toggle = 'user_engagement_wizard_addon_toggle',
  user_engagement_wizard_no_wc = 'user_engagement_wizard_no_wc',
  user_engagement_wizard_tone_of_voice = 'user_engagement_wizard_tone_of_voice',
  click_wizard_pre_processing = 'click_wizard_pre_processing',
  click_finish_later = 'click_wizard_finish_po_later_link',
  sent_finish_later = 'user_engagement_wizard_finish_po_later_link_sent',
  pageview_thankyou = 'pageview_thankyou',
  pageview_thankyou_project = 'pageview_thankyou_project',
  click_shopping_cart_success_purchase_from_checkout = 'click_shopping_cart_success_purchase_from_checkout',
  purchase = 'purchase',
  first_purchase = 'first_purchase',
  non_first_purchase = 'non_first_purchase',
  sale_package_checkout = 'sale_package_checkout',
  checkout_download_proposal = 'checkout_download_proposal',
  click_chat = 'click_sidebar_blend_talk',
  additional_service_selected = 'click_payment_additional_service',
  pageview_payment = 'pageview_payment',
  expedite_selected = 'click_payment_expedite',
  click_payment_methods = 'click_payment_methods',
  coupon_applied = 'click_payment_coupon_applied',
  coupon_removed = 'click_payment_coupon_removed',
  checkout = 'click_shopping_cart_checkout',
  header_click = 'click_shopping_cart_button_in_header',
  filestack_file_select = 'wizard_filestack_click_file_select',
  funnel_FT_upload_file_fail = 'funnel_FT_upload_file_fail',
  pageview_wizard_brief_changed = 'pageview_wizard_brief_changed',
  page_view_brief_reference_upload = 'pageview_wizard_brief_reference_upload',
  plain_text_entered = 'user_engagement_wizard_plain_text_entered',
  funnel_FT_entry = 'funnel_FT_entry',
  signup_fail = 'signup_fail',
  login_fail = 'login_fail',
  login_success = 'login_success',
  registration = 'registration',
  signup_success = 'signup_success',
  logout = 'logout',
  page_view = 'page_view',
  click_sort_by_alphabet = 'click_sort_by_alphabet',
  click_sort_by_top_used = 'click_sort_by_top_used',
  click_save_language_set = 'click_save_language_set',
  click_load_language_set = 'click_load_language_set',
  click_language_delete = 'click_language_delete',
  funnel_FT_click_translator_cta = 'funnel_FT_click_translator_cta',
}

export enum UserSegment {
  new = 'new',
  existing = 'existing',
}
