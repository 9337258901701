'use client';

import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg {
    width: inherit;
    height: inherit;
  }
`;
