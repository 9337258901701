import {SummaryConfigType} from '@/src/interfaces/types/wizard.types';

import CONSTS from '../WizardViewConsts';

const {TOTAL_WC, EST_DELIVERY, ORDER_TOTAL, TOTAL_MEDIA} = CONSTS;

export const summaryConfig: SummaryConfigType = {
  translation: [
    [
      {
        label: TOTAL_WC,
        isBold: true,
        field: 'wordCount',
        isRequiredToDisplay: true,
        defaultValue: '0',
      },
      {
        label: EST_DELIVERY,
        format: 'date',
        field: 'deliveryDate',
        isRequiredToDisplay: true,
        defaultValue: '',
      },
    ],
    [
      // this block is for Translation To *languageLabel* list
    ],
    [
      {
        label: ORDER_TOTAL,
        isMajorValue: true,
        field: 'total',
        isCurrency: true,
        isRequiredToDisplay: true,
        defaultValue: '0',
      },
    ],
  ],
  proofreading: [
    [
      {
        label: TOTAL_WC,
        isBold: true,
        field: 'wordCount',
        isRequiredToDisplay: true,
        defaultValue: '0',
      },
      {
        label: EST_DELIVERY,
        format: 'date',
        field: 'deliveryDate',
        isRequiredToDisplay: true,
        defaultValue: '',
      },
    ],
    [
      // this block is for *targetLanguage* proofreading list
    ],
    [
      {
        label: ORDER_TOTAL,
        isMajorValue: true,
        field: 'total',
        isCurrency: true,
        isRequiredToDisplay: true,
        defaultValue: '0',
      },
    ],
  ],
  transcription: [
    [
      {
        label: TOTAL_MEDIA,
        isBold: true,
        field: 'wordCount',
        format: 'media',
        isRequiredToDisplay: true,
        defaultValue: '0',
      },
      {
        label: EST_DELIVERY,
        format: 'date',
        field: 'deliveryDate',
        isRequiredToDisplay: true,
        defaultValue: '',
      },
    ],
    [
      // this block is for *targetLanguage* transcription list
    ],
    [
      {
        label: ORDER_TOTAL,
        isMajorValue: true,
        field: 'total',
        isCurrency: true,
        isRequiredToDisplay: true,
        defaultValue: '0',
      },
    ],
  ],
};
