import axios, {AxiosError} from 'axios';

import {isBuildTime, isServer} from '../utils/environment';

const baseURL = process.env.FRONTEND_API_BASE_URL;

export const instance = axios.create({baseURL});

instance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error?.code && !['ECONNABORTED', 'ERR_CANCELED'].includes(error.code)) {
      console.error(error);
    }
    return Promise.reject(error);
  }
);

instance.interceptors.request.use(async (config) => {
  if (!isServer() || isBuildTime()) {
    return config;
  }

  const {headers} = await import('next/headers');
  const headerStore = headers();
  config.headers['x-forwarded-for'] = headerStore.get('x-forwarded-for') || '';

  return config;
});

export default instance;
