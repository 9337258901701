'use client';

import Button from '@mui/material/Button';
import styled from 'styled-components';

import {font_medium} from '@/theme/fonts';

export const Wrapper = styled(Button)`
  ${font_medium};
  display: flex;
  align-items: center;
`;
