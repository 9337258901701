import axios from 'axios';

import {UserDetails} from '@/src/interfaces/types/adminSubmarine.types';

import core from './core';

export const getUserToAct = (id: string) => core.get(`/manage/act?NoRedirect=1&uid=${id}`);

export const restoreAdminAccount = () => core.get(`/manage/act?NoRedirect=1`);

export const resetAdminCountry = () => core.post(`/api/2/admin/country/reset-locale`).then(({data}) => data);

export const setAdminCountry = (data: {admin_locale: string}) =>
  axios({
    method: 'POST',
    url: `/api/2/admin/country/set-locale`,
    data,
    headers: {'Content-Type': 'application/json'},
  }).then(({data: responseData}) => responseData);

export const getUserDetails = (id: string): Promise<{data: UserDetails}> =>
  core.get(`/adminService/getUserDescription?uid=${id}`);
