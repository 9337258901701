import {FC, ReactNode} from 'react';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import BaseLink from '@/src/components/BaseComponents/BaseLink';
import {summaryConfig} from '@/src/components/Wizard/configs/summary.config';
import {
  LanguageCostType,
  ProjectTypeConfigType,
  QuoteType,
  SummaryRecordType,
} from '@/src/interfaces/types/wizard.types';
import {FileData, IPriceQuote, StorageFileData, WizardData} from '@/src/lib/store/stores/wizard';
import {WizardProjectType} from '@/src/types/Wizard/types';
import {getItem, setItem} from '@/src/utils/localStorage';
import {capitalize} from '@/src/utils/stringActions';

import CONSTS from './WizardViewConsts';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const {
  SERVICE_CERTIFICATE_EDITING,
  SERVICE_EDITING_TEXT,
  TRANSLATE_FROM,
  SELECT_LANGUAGE_PROOFREADING,
  SELECT_LANGUAGE_TRANSCRIPTION,
  EDITING_TOOLTIP_TEXT,
  CERTIFICATE_TOOLTIP_TEXT,
  PDF_FILETYPE,
  WC_INACCURATE_MODAL,
  FILES_ERROR_TEXT,
  PAGES_ERROR_TEXT,
  SOURCE_LANG_ERROR,
  TARGET_LANG_ERROR,
  TOPIC_ERROR,
  BRIEF_MAX_SYMBOLS_NUMBER,
  TOTAL_PC,
  TOTAL_WC,
  TOTAL_MEDIA,
} = CONSTS;
const {TRANSCRIPTION, TRANSLATION, PROOFREADING} = WizardProjectType;

export const allowedWizardTypes: WizardProjectType[] = [TRANSLATION, PROOFREADING, TRANSCRIPTION];

export enum ContentTypeEnum {
  FILES = 'FILES',
  TEXT = 'TEXT',
}

export enum FilesTranslationVariantEnum {
  AS_IS = 'AS_IS',
  CUSTOM = 'CUSTOM',
}
export interface PickerCroppedData {
  cropArea: {
    position: [number, number];
    size: [number, number];
  };
  originalImageSize: [number, number];
}

export declare enum RotateDirection {
  cw = 'CW',
  ccw = 'CCW',
}
export interface PickerRotatedData {
  value: number;
  direction: RotateDirection;
}

export interface PickerFileMetadata {
  container?: string;
  cropped?: PickerCroppedData;
  filename: string;
  handle: string;
  key?: string;
  mimetype: string;
  originalFile?: object | File;
  originalPath: string;
  rotated?: PickerRotatedData;
  size: number;
  source: string;
  status?: string;
  uploadId: string;
  url: string;
  alt: string;
}

export const getFileUploadData = (
  data: PickerFileMetadata | PickerFileMetadata[],
  fileIndex: number,
  context = 'source'
): FormData => {
  const fd = new FormData();
  const filesArray = Array.isArray(data) ? data : [data];
  filesArray.map((item, arrayIndex) => {
    const index = arrayIndex || fileIndex;
    const prefix = `filePicker[${index}]`;
    Object.entries(item).forEach(([key, value]) => {
      if (value && typeof value === 'object') {
        Object.entries(value).forEach(([nestedKey, nestedValue]) => {
          fd.append(`${prefix}[${key}][${nestedKey}]`, String(nestedValue));
        });
      } else {
        fd.append(`${prefix}[${key}]`, String(value));
      }
    });
    fd.append(`${prefix}[context]`, context);
  });
  return fd;
};

const getSummaryContent = ({type, languages = [], total, professionalEditing}: QuoteType): SummaryRecordType[] => {
  switch (type) {
    case 'translation':
      const prefix = professionalEditing ? '+ Editing' : '';
      const records: SummaryRecordType[] | undefined = languages?.map(
        ({languageLabel, price: value}: LanguageCostType) => ({
          label: `Translation ${prefix} to ${languageLabel}`,
          value: value,
        })
      );

      return records || [];
    case 'proofreading':
      return languages.length > 0 ? [{label: `${languages[0].languageLabel} proofreading`, value: total}] : [];

    case 'transcription':
      return languages.length > 0 ? [{label: `${languages[0].languageLabel} transcription`, value: total}] : [];

    default:
      return [];
  }
};

/**
 * Formats a number into a two-digit string.
 * @param value - The number to format.
 * @returns A string with two digits.
 */
const formatToTwoDigits = (value: number): string => (value < 10 ? `0${value}` : value.toString());

/**
 * Converts word count (interpreted as seconds) into a time string in HH:MM:SS format.
 * @param wordCount - The word count, interpreted as seconds.
 * @returns A time string in HH:MM:SS format.
 */

export const convertWordCountToTime = (wordCount: number): string => {
  const hours = Math.floor(wordCount / 3600);
  const minutes = Math.floor((wordCount % 3600) / 60);
  const seconds = wordCount % 60;

  return `${formatToTwoDigits(hours)}:${formatToTwoDigits(minutes)}:${formatToTwoDigits(seconds)}`;
};

export const convertTimeToWordCount = (time: string): number => {
  const [hours, minutes, seconds] = time.split(':').map(Number);

  if (
    isNaN(hours) ||
    isNaN(minutes) ||
    isNaN(seconds) ||
    hours < 0 ||
    minutes < 0 ||
    minutes >= 60 ||
    seconds < 0 ||
    seconds >= 60
  ) {
    throw new Error('Invalid time format');
  }

  return hours * 3600 + minutes * 60 + seconds;
};

const getFieldValue = (value: any, format: string, wordcount = 0): string => {
  switch (format) {
    case 'date':
      return dayjs.unix(value).tz('GMT').format('MMMM Do, HH:mm [GMT]');
    case 'media':
      return convertWordCountToTime(wordcount);
    default:
      return value;
  }
};

export const getSummaryData = (data: WizardData, priceQuote?: IPriceQuote): QuoteType => {
  const totalWc = data.files?.reduce((totalCount: number, fileData) => totalCount + fileData.wordcount, 0);
  const totalPc = data.files?.reduce((totalCount: number, fileData) => totalCount + (fileData.pageCount || 0), 0);
  const finalWcValue = data.isPageCount ? totalPc : totalWc;
  const wordCount = priceQuote?.po.totalWordCount || finalWcValue;
  const languages: LanguageCostType[] | undefined = priceQuote?.price_breakdown
    ? Object.entries(priceQuote.price_breakdown).map(([languageLabel, price]) => ({
        languageLabel,
        price,
      }))
    : undefined;
  const todayDate = Math.floor(new Date().getTime() / 1000);
  const timeInSeconds = priceQuote?.po.deliveryData.origin.timeEstimation
    ? priceQuote?.po.deliveryData.origin.timeEstimation * 60
    : 0;
  const deliveryTime = todayDate + timeInSeconds;

  return {
    type: priceQuote?.po?.type || data.projectType,
    wordCount,
    deliveryDate: priceQuote?.po.deliveryData.origin.timeEstimation ? deliveryTime : undefined,
    languages,
    total: priceQuote?.price || 0,
    isPageCount: data.isPageCount,
    professionalEditing: data.professionalEditing,
  };
};

export const getData = (data: QuoteType): SummaryRecordType[][] => {
  const config = summaryConfig[data.type];

  if (!config) {
    return [];
  }

  const getWCLabel = () => {
    switch (data?.type) {
      case TRANSLATION:
      case PROOFREADING:
        return TOTAL_WC;

      case TRANSCRIPTION:
        return TOTAL_MEDIA;

      default:
        return TOTAL_PC;
    }
  };
  const valuesList: SummaryRecordType[][] = config.reduce(
    (list: SummaryRecordType[][], configGroup: ProjectTypeConfigType[]) => {
      const items: SummaryRecordType[] = configGroup.reduce(
        (groupList: SummaryRecordType[], {field, ...rest}: ProjectTypeConfigType) => {
          const rowValue = data?.[field] || rest.defaultValue;
          if (field === 'wordCount') {
            rest.label = data.isPageCount ? TOTAL_PC : getWCLabel();
          }

          const value =
            rowValue && rest.format
              ? (getFieldValue(rowValue, rest.format, data?.wordCount) as string)
              : (rowValue as string);
          return value || rest.isRequiredToDisplay
            ? [
                ...groupList,
                {
                  value,
                  ...rest,
                },
              ]
            : groupList;
        },
        []
      );

      return [...list, items];
    },
    []
  );

  const content: SummaryRecordType[] = getSummaryContent(data);
  content.forEach((record) => (record.isCurrency = true));
  valuesList[1] = content;

  const valuesFiltered = valuesList.filter((arr) => arr.length);

  return valuesFiltered;
};

type ValidType = (typeof allowedWizardTypes)[number];

export const isValidWizardType = (type?: string | string[]): type is ValidType =>
  typeof type === 'string' && allowedWizardTypes.includes(type as ValidType);

export enum AdditionalServiceType {
  EDITING = 'editing',
  CERTIFICATE = 'certificate',
}

const {EDITING, CERTIFICATE} = AdditionalServiceType;

export const getCheckboxTitle = (type: AdditionalServiceType) => {
  const result: Record<AdditionalServiceType, string> = {
    [EDITING]: SERVICE_EDITING_TEXT,
    [CERTIFICATE]: SERVICE_CERTIFICATE_EDITING,
  };

  return result[type] || '';
};

export interface WizardPrice {
  value: string;
  color: string;
  isCrossedOut?: boolean;
}

export interface AdditionalServiceItem {
  type: string;
  tooltip: ReactNode | string;
  serviceType: AdditionalServiceType;
}

const CertificateTooltipText: FC = () => (
  <span>
    {CERTIFICATE_TOOLTIP_TEXT}
    <BaseLink
      href={`${process.env.FRONTEND_API_BASE_URL || ''}/public/resources/CertificateSample.pdf`}
      target="_blank"
    >
      here
    </BaseLink>
  </span>
);

export const additionalServices: AdditionalServiceItem[] = [
  {
    type: getCheckboxTitle(EDITING),
    tooltip: EDITING_TOOLTIP_TEXT,
    serviceType: EDITING,
  },
  {
    type: getCheckboxTitle(CERTIFICATE),
    tooltip: <CertificateTooltipText />,
    serviceType: CERTIFICATE,
  },
];

export interface SourceLanguage {
  id: number;
  code: string;
  slug: string;
  name: string;
}

export interface LanguageResponse {
  Language: ILanguagesResult;
}

export interface FeaturePriceConfig {
  price: number;
  unit: string;
}

export interface PriceConfigResponse {
  Price: IPriceConfig;
}

export type IPriceConfig = Record<string, FeaturePriceConfig>;

export interface TargetLanguagesResponse {
  data: TargetLanguage[];
}

interface LanguageResultInstance {
  fullname: string;
  shortname: string;
  slug: string;
  group?: string;
  order?: number;
  code?: string;
}

export interface ILanguagesResult {
  grouped: {
    groups: Record<
      string,
      {
        name: string;
        flag: string;
      }
    >;
    languages: Record<string, LanguageResultInstance>;
  };
  list: SourceLanguage[];
  ungrouped: Record<string, string>;
}

export interface IPrePostProcessingSettings {
  enable: number;
  forced_types: string[];
  types: Record<string, number>;
}

export interface IRegistryDynamicProject {
  key: WizardProjectType;
  title: string;
  shortTitle: string;
  count_unit: ProjectCountUnitEnum;
  weight: number;
}

export interface IRegistryDynamicProjectResponse {
  DynamicProjects: IRegistryDynamicProject[];
}

export interface IPrePostProcessingSettingsResponse {
  PrePostProcessingSettings: IPrePostProcessingSettings;
}

export interface Language {
  id: number;
  name: string;
  slug: string;
}

export function isLanguage(item: unknown): item is Language {
  return typeof item === 'object' && item !== null && 'id' in item && 'name' in item && 'slug' in item;
}

export function isLanguageGroup(item: unknown): item is LanguageGroup {
  return typeof item === 'object' && item !== null && 'name' in item && 'child' in item;
}

export interface LanguageGroup {
  name: string;
  child: Language[];
}
export interface LanguageProps extends Language {
  code: string;
  fullname: string;
  shortname: string;
  group?: string;
  order?: number;
  child?: LanguageChildProps[];
  availability?: string;
  msg?: string;
}

export interface TargetLanguageProps extends TargetLanguage {
  group?: string | null;
  child?: TargetLanguageProps[];
}

interface LanguageChildProps {
  fullname: string;
  name: string;
  group?: string;
  order?: number;
  shortname: string;
  slug: string;
  id: number;
  code: string;
}

export interface GroupProps extends LanguageGroup {
  child: LanguageProps[];
}

export type GroupedLanguageProps = Record<string, GroupProps>;
export type GroupedTargetLanguageProps = Record<string, GroupedTargetProps>;
export interface GroupedTargetProps extends LanguageGroup {
  child: TargetLanguageProps[];
}
export const languageNameTransformation = (name: string, group: string) => {
  const withoutBrackets = name.replace(/[()]/g, '');
  const groupName = group.charAt(0).toUpperCase() + group.slice(1);

  return (
    withoutBrackets === groupName
      ? withoutBrackets
      : withoutBrackets.replace(group.charAt(0).toUpperCase() + group.slice(1), '')
  ).trim();
};

export const formSourceLanguages = (languages: ILanguagesResult | null) => {
  const search = '';
  const groupedSourceLanguages: LanguageProps[] = [];
  const unGroupedSourceLanguages: LanguageProps[] = [];
  const searchText: string = search?.toLowerCase();
  const grouped = languages?.grouped?.languages;

  grouped &&
    Object.values(grouped).forEach((item) => {
      const {id, code} = languages?.list.find(({slug}) => item.slug === slug) || {};

      if (id && code) {
        const option: LanguageProps = {...item, id, code, name: item.fullname};
        item.group ? groupedSourceLanguages.push(option) : unGroupedSourceLanguages.push(option);
      }
    });

  const updatedGroupedSourceLanguages: GroupProps[] = Object.values(
    groupedSourceLanguages.reduce((acc: GroupedLanguageProps, cur: LanguageProps) => {
      const {group} = cur;

      if (group) {
        acc[group] = acc[group] || {
          name: capitalize(group),
          child: [],
        };

        acc[group].child.push(cur);
      }

      return acc;
    }, {})
  );

  updatedGroupedSourceLanguages.forEach(({child}) => {
    child?.length === 1 && unGroupedSourceLanguages.push(child[0]);
  });

  const noSingleGroupedSourceLanguages = updatedGroupedSourceLanguages.filter(({child}) => child && child?.length > 1);

  return [...unGroupedSourceLanguages.sort((a, b) => (a.name < b.name ? -1 : 1)), ...noSingleGroupedSourceLanguages]
    .filter(({name, child}) =>
      child?.length
        ? child?.some((item) => item.name.toLowerCase().indexOf(searchText) !== -1)
        : name.toLowerCase().includes(searchText)
    )
    .sort((a, b) => (a.name < b.name ? -1 : 1));
};

export const formTargetLanguages = (
  allTargetLanguages: TargetLanguage[],
  sourceLanguageSlug?: string
): (TargetLanguageProps | GroupedTargetProps)[] => {
  const groupedTargetLanguages: TargetLanguageProps[] = [];
  const unGroupedTargetLanguages: TargetLanguageProps[] = [];

  allTargetLanguages
    .filter(({slug}) => slug !== sourceLanguageSlug)
    .forEach((item: TargetLanguageProps) => {
      item.group ? groupedTargetLanguages.push(item) : unGroupedTargetLanguages.push(item);
    });

  const updatedGroupedTargetLanguages = Object.values(
    groupedTargetLanguages.reduce((acc: GroupedTargetLanguageProps, cur: TargetLanguageProps) => {
      const {group} = cur;

      if (group) {
        acc[group] = acc[group] || {
          name: capitalize(group),
          child: [],
        };

        acc[group].child.push(cur);
      }

      return acc;
    }, {})
  );

  updatedGroupedTargetLanguages.forEach(({child}) => {
    child?.length === 1 && unGroupedTargetLanguages.push(child[0]);
  });

  const noSingleGroupedTargetLanguages = updatedGroupedTargetLanguages.filter(({child}) => child && child?.length > 1);

  return [...noSingleGroupedTargetLanguages, ...unGroupedTargetLanguages.sort((a, b) => (a.name < b.name ? -1 : 1))];
};

export interface TargetLanguage extends Language {
  availability: string;
  msg: string;
}

export enum LanguagesSortEnum {
  TOP_USED = 'Top used',
  ALPHABET = 'Alphabet',
}

export const languagesSortOptions = [
  {
    value: LanguagesSortEnum.TOP_USED,
    label: 'Top used',
  },
  {
    value: LanguagesSortEnum.ALPHABET,
    label: 'Alphabet',
  },
];

export enum SortByEnum {
  NAME = 'name',
  POPULARITY = 'popularity',
}

export enum OrderByEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const translateFromNameMap = {
  [TRANSLATION]: TRANSLATE_FROM,
  [PROOFREADING]: SELECT_LANGUAGE_PROOFREADING,
  [TRANSCRIPTION]: SELECT_LANGUAGE_TRANSCRIPTION,
};

export interface LanguageSetResponse {
  Config: string;
  UserId: number;
}

export interface SetMutationObject {
  setName: string;
  sourceLanguage: string;
  targetLanguages: string[];
  default: number;
}

const ecxel_ext = [
  'application/vnd.ms-excel',
  'application/msexcel',
  'application/x-msexcel',
  'application/x-ms-excel',
  'application/x-excel',
  'application/x-dos_ms_excel',
  'application/xls',
  'application/x-xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const getBaseRequestData = (
  CSRFToken: string,
  data: WizardData,
  poid: string | null,
  priceQuote?: IPriceQuote
): FormData => {
  const fd = new FormData();
  fd.append('poid', priceQuote?.po?.poid || priceQuote?.po?._id.$oid || poid || '');
  fd.append('status', '1');
  fd.append('wizardVersion', String(data.wizardVersion));
  fd.append('type', data.projectType || priceQuote?.po?.type);
  fd.append('projectType', data.projectType || priceQuote?.po?.type);
  fd.append('source_language', data.sourceLanguage?.slug || '');
  if (data.targetLanguages) {
    data.targetLanguages.forEach(({slug}) => {
      fd.append('target_languages[]', slug);
    });
  } else {
    fd.append('target_languages', '');
  }
  fd.append('expertise', data.topic);
  fd.append('note', data.brief || '');
  fd.append('service', 'project');
  fd.append('entQ', 'false');
  fd.append('useTM', 'false'); // todo make sure it's correct
  fd.append('certificate_note', data.cartificateNote);
  fd.append('needExpedite', 'false'); // under Delivery Time option Expedite should be as checkbox. It's present at payment page now.
  fd.append('isPrePostProcessingProject', 'false'); // in case we have modal that words can't be counted and user pressing YES it should be true
  const filesWordCount: number =
    data.files?.reduce((totalCount: number, fileData) => totalCount + fileData.wordcount, 0) || 0;
  const textResourceWordCount: number = data.textResource?.word_count || 0;
  const totalWordCount: number = filesWordCount + textResourceWordCount;
  const filesPageCount: number =
    data.files?.reduce((totalCount: number, fileData) => totalCount + (fileData.pageCount || 0), 0) || 0;
  fd.append('totalWordCount', String(data.isPageCount ? filesPageCount : totalWordCount));
  // fd.append('totalSeoPageCount', '0'); // looks like it's when projectType is SEO
  fd.append('CSRFToken', CSRFToken);
  if (data.toneOfVoice) {
    fd.append('tone_of_voice', data.toneOfVoice);
  }
  const isNotACertificate: boolean = data.topic !== String(ExperiseIdEnum.CERTIFICATE);
  if (isNotACertificate) {
    fd.append('needProofreading', String(data.professionalEditing));
    fd.append('send_certificate', String(data.sendCertificate));
  }
  if (data.files) {
    data.files.forEach(({uuid, type, wordcount, mimeType, fileTranslationVariant, pageCount}, index: number) => {
      const isPageCount: boolean = ['460', '335'].includes(data.topic);
      const isExcelFile = ecxel_ext.includes(mimeType);
      const filePageCount: number = pageCount || 0;

      fd.append(`resources[${index}][uuid]`, uuid);
      fd.append(`resources[${index}][context]`, 'source');
      fd.append(`resources[${index}][type]`, type);
      fd.append(`resources[${index}][word_count]`, String(wordcount));
      fd.append(`resources[${index}][word_count_auto]`, String(wordcount));
      fd.append(`resources[${index}][word_count_manual]`, String(wordcount));
      fd.append(`resources[${index}][page_count]`, String(isPageCount ? filePageCount : 0)); // todo fix wordcoint - it's incorrect
      fd.append(`resources[${index}][filetype]`, mimeType);

      if (isExcelFile) {
        fd.append(`resources[${index}][isExcelFile]`, String(isExcelFile));
        const isBlockCatTool: boolean = fileTranslationVariant !== FilesTranslationVariantEnum.AS_IS;
        const excelRadioValue: string = isBlockCatTool ? 'according_the_instructions' : 'whole_document';
        fd.append(
          `resources[${index}][blockCATTool]`,
          String(fileTranslationVariant && data.projectType === TRANSLATION ? isBlockCatTool : '')
        );
        fd.append(
          `resources[${index}][excelRadioValue]`,
          String(fileTranslationVariant && data.projectType === TRANSLATION ? excelRadioValue : '')
        );
      }
    });
  }
  return fd;
};

const getTranslationProjectTypeRequestData = (
  CSRFToken: string,
  data: WizardData,
  poid: string | null,
  priceQuote?: IPriceQuote
): FormData => {
  const payload: FormData = getBaseRequestData(CSRFToken, data, poid, priceQuote);
  const lastFilesIndex = data.files?.length || 0;
  const textResourceIndex: number = data.textResource ? lastFilesIndex : 0;
  const isNotACertificate: boolean = data.topic !== String(ExperiseIdEnum.CERTIFICATE);
  if (data.textResource && isNotACertificate) {
    payload.append(`resources[${textResourceIndex}][uuid]`, data.textResource.uuid);
    payload.append(`resources[${textResourceIndex}][word_count]`, String(data.textResource.word_count));
    payload.append(`resources[${textResourceIndex}][word_count_auto]`, String(data.textResource.word_count_auto));
    payload.append(`resources[${textResourceIndex}][word_count_manual]`, String(data.textResource.word_count));
    payload.append(`resources[${textResourceIndex}][filetype]`, data.textResource.filetype);
    payload.append(`resources[${textResourceIndex}][type]`, data.textResource.type);
    payload.append(`resources[${textResourceIndex}][context]`, data.textResource.context);
    payload.append(`resources[${textResourceIndex}][filename]`, data.textResource.filename);
    payload.append(`resources[${textResourceIndex}][text]`, data.textResource.text);
  }
  if (data.briefFiles && isNotACertificate) {
    const startIndex: number = data.textResource ? textResourceIndex + 1 : lastFilesIndex;
    data.briefFiles.forEach((file, fileIndex: number) => {
      payload.append(`resources[${startIndex + fileIndex}][uuid]`, file.uuid);
      payload.append(`resources[${startIndex + fileIndex}][context]`, 'reference');
      payload.append(`resources[${startIndex + fileIndex}][type]`, 'text');
      payload.append(`resources[${startIndex + fileIndex}][word_count]`, String(file.wordcount));
      payload.append(`resources[${startIndex + fileIndex}][word_count_auto]`, String(file.wordcount));
      payload.append(`resources[${startIndex + fileIndex}][word_count_manual]`, String(file.wordcount));
      payload.append(`resources[${startIndex + fileIndex}][page_count]`, '0');
      payload.append(`resources[${startIndex + fileIndex}][filetype]`, file.mimeType);
    });
  }
  return payload;
};

const getProofreadingProjectTypeRequestData = (
  CSRFToken: string,
  data: WizardData,
  poid: string | null,
  priceQuote?: IPriceQuote
) => {
  const payload: FormData = getBaseRequestData(CSRFToken, data, poid, priceQuote);
  const startIndex = data.files?.length || 0;
  if (data.briefFiles && data.topic !== String(ExperiseIdEnum.CERTIFICATE)) {
    data.briefFiles.forEach((file, fileIndex: number) => {
      payload.append(`resources[${startIndex + fileIndex}][uuid]`, file.uuid);
      payload.append(`resources[${startIndex + fileIndex}][context]`, 'reference');
      payload.append(`resources[${startIndex + fileIndex}][type]`, 'text');
      payload.append(`resources[${startIndex + fileIndex}][word_count]`, String(file.wordcount));
      payload.append(`resources[${startIndex + fileIndex}][word_count_auto]`, String(file.wordcount));
      payload.append(`resources[${startIndex + fileIndex}][word_count_manual]`, String(file.wordcount));
      payload.append(`resources[${startIndex + fileIndex}][page_count]`, '0');
      payload.append(`resources[${startIndex + fileIndex}][filetype]`, file.mimeType);
    });
  }
  return payload;
};

export const getPriceQuoteRequestData = (
  CSRFToken: string,
  data: WizardData,
  poid: string | null,
  priceQuote?: IPriceQuote
): FormData | null => {
  if (data.projectType === TRANSLATION) {
    return getTranslationProjectTypeRequestData(CSRFToken, data, poid, priceQuote) as FormData | null;
  }
  if ([PROOFREADING, TRANSCRIPTION].includes(data.projectType)) {
    return getProofreadingProjectTypeRequestData(CSRFToken, data, poid, priceQuote) as FormData | null;
  }
  console.error('The price quote for the selected project type is not supported. Be soon...');
  return null;
};

export enum ProjectCountUnitEnum {
  PAGES = 'page',
  WORD = 'word',
  SECONDS = 'seconds',
}

export enum ExperiseIdEnum {
  CERTIFICATE = 460,
  CV = 335,
}

export const getProjectCountUnit = (
  projectType: WizardProjectType,
  expertise: string,
  dynamicProjects?: IRegistryDynamicProject[]
): ProjectCountUnitEnum => {
  const projectModel = dynamicProjects?.find(({key}) => key === projectType);

  const isPages = [ExperiseIdEnum.CERTIFICATE, ExperiseIdEnum.CV].includes(+expertise);

  if (isPages) {
    return ProjectCountUnitEnum.PAGES;
  }

  return projectModel?.count_unit || ProjectCountUnitEnum.PAGES;
};

export const getIsInaccurateModalNeeded = (data: WizardData, priceQuote: IPriceQuote) => {
  const isNotProofreading = data.projectType !== PROOFREADING;
  const isNotACertificate = data.topic !== String(ExperiseIdEnum.CERTIFICATE);
  const wordCountFiles =
    priceQuote?.po?.resources.filter(({context, word_count, uuid, filetype}) => {
      const fileDataLocal = data.files?.find(({uuid: localUuid}) => localUuid === uuid);
      const wasProcessed = fileDataLocal ? !fileDataLocal.wasProcessed : false;
      const isPDF = filetype === PDF_FILETYPE;
      return context === 'source' && word_count > 0 && wasProcessed && isPDF;
    }) || [];

  const showInaccurateCountModal = isNotProofreading && !!wordCountFiles.length && isNotACertificate;
  const shouldSkipModal = getItem(WC_INACCURATE_MODAL);

  return {
    modalNeeded: showInaccurateCountModal && !shouldSkipModal,
    processFilesList: wordCountFiles.length ? wordCountFiles : null,
  };
};

export interface ErrorFields<T> {
  languages: T;
  topic: T;
  files: T;
  brief: T;
}

export const validateWizardData = (data: WizardData): WizardData['errors'] => {
  const sourceLangError: string[] = !data.sourceLanguage ? [SOURCE_LANG_ERROR] : [];
  const targetLangError: string[] =
    data.projectType === TRANSLATION && !data.targetLanguages?.length ? [TARGET_LANG_ERROR] : [];
  const langErrorsFullList: string[] = [...sourceLangError, ...targetLangError];
  const excelFilesError = !!data.files?.some((file) => file.isExcelFile && !file.fileTranslationVariant);
  const wordcountFilesError = !!data.files?.some((file) => (data.isPageCount ? !file.pageCount : !file.wordcount));
  const wordcountTextResourceError = data.textResource ? !data.textResource?.word_count : false;

  const fileTextError: string = data.isPageCount ? PAGES_ERROR_TEXT : FILES_ERROR_TEXT;

  return {
    files:
      !(data.files?.length || data.textResource) || wordcountFilesError || wordcountTextResourceError || excelFilesError
        ? [excelFilesError ? ' ' : fileTextError]
        : null,
    languages: langErrorsFullList.length ? langErrorsFullList : null,
    topic: !data.topic ? [TOPIC_ERROR] : null,
    brief: data.brief && data.brief.length >= BRIEF_MAX_SYMBOLS_NUMBER ? [' '] : null,
  };
};

export const convertStorageFileDataToLocal = (filesData?: StorageFileData[]): FileData[] | null =>
  filesData?.map(
    ({
      uuid,
      filename,
      wordcount,
      type,
      is_scanned,
      size,
      mimeType,
      isExcelFile,
      fileTranslationVariant,
      error,
      wasProcessed,
      pageCount,
    }) => ({
      uuid,
      filename,
      wordcount,
      type,
      is_scanned,
      size,
      mimeType,
      isExcelFile,
      fileTranslationVariant,
      error,
      wasProcessed,
      pageCount,
    })
  ) || null;

export const saveWizardData = (data: WizardData, priceQuote: IPriceQuote): void => {
  try {
    const filesData: StorageFileData[] = priceQuote.po.resources
      .map((poResource) => {
        const localFileData = data.files?.find(({uuid}) => uuid === poResource.uuid);
        if (!localFileData) {
          return null;
        }
        return {
          ...poResource,
          ...localFileData,
        };
      })
      .filter((item) => !!item);
    const wizardData = {
      poid: priceQuote.po._id.$oid || priceQuote.po.poid,
      filesData,
      ...data,
    };
    setItem('poData', JSON.stringify(wizardData));
  } catch (error) {
    console.error(error);
  }
};
