import {AxiosResponse} from 'axios';
import queryString from 'query-string';

import core from './core';
import {OptionsProps} from '../components/BaseComponents/BaseSingleSelect';
import {
  IPrePostProcessingSettingsResponse,
  IRegistryDynamicProjectResponse,
  LanguageResponse,
  OrderByEnum,
  PriceConfigResponse,
  SortByEnum,
  TargetLanguagesResponse,
} from '../components/Wizard/WizardViewUtils';
import {FileData, IPriceQuote} from '../lib/store/stores/wizard';
import {
  ApiResponse,
  ConfigFinishLater,
  FetchTargetLanguagesParams,
  IHubspotFinishLaterResponse,
  UpdatedTextResult,
  WizardFinalizeResult,
  WizardProjectType,
} from '../types/Wizard/types';

export const fetchPriceQuote = (data: FormData, signal: AbortSignal): Promise<IPriceQuote> =>
  core
    .post('/api/2/wizard/pricequote', data, {signal})
    .then((response: AxiosResponse<ApiResponse<IPriceQuote>>) => response.data.results);

export const fetchTopics = (projectType: WizardProjectType): Promise<OptionsProps[]> => {
  const params = new URLSearchParams({
    service_type: projectType,
  });
  return core.get(`/bff/topics`, {params}).then((response: AxiosResponse<OptionsProps[]>) => response.data);
};

export const fetchLanguages = (): Promise<ApiResponse<LanguageResponse>> =>
  core.get('/registry/get?data=Language').then((response) => response.data);

export const fetchPriceConfig = (): Promise<ApiResponse<PriceConfigResponse>> =>
  core.get('/registry/get?data=Price').then((response) => response.data);

export const fetchTargetLanguages = ({
  slug,
  sortBy = SortByEnum.NAME,
  orderBy = OrderByEnum.ASC,
}: FetchTargetLanguagesParams): Promise<ApiResponse<TargetLanguagesResponse>> =>
  core
    .get(`/api/2/wizard/getavailablelanguagesBySlug`, {
      params: {slug, sortBy, orderBy},
    })
    .then((response) => response.data);

export const postUploadedFile = (data: FormData): Promise<AxiosResponse<ApiResponse<FileData[]>>> =>
  core.post('/ajax/filepicker/fileUploaded', data);

export const postUpdatedText = (data: FormData): Promise<AxiosResponse<ApiResponse<UpdatedTextResult>>> =>
  core.post('/wizard/updateText', data);

export const finalizeOrder = (fd: FormData): Promise<AxiosResponse<ApiResponse<WizardFinalizeResult>>> =>
  core.post('/api/2/wizard/finalize', fd);

export const getIsPrePostProcessingSettings = (): Promise<
  AxiosResponse<ApiResponse<IPrePostProcessingSettingsResponse>>
> => core.get('/bff/prePostProcessingSettings');

export const getRegistryDynamicProjects = (): Promise<AxiosResponse<ApiResponse<IRegistryDynamicProjectResponse>>> =>
  core.get('/bff/registryDynamicProjects');

export const updateRegisterInfo = (CSRFToken: string, params: any) =>
  core.post(
    `/userService/updateRegisterInfo?CSRFToken=${CSRFToken}`,
    queryString.stringify(params, {
      skipNull: true,
    })
  );

export const notifyHubspotFinishLater = (
  config: ConfigFinishLater,
  email: string
): Promise<IHubspotFinishLaterResponse> => {
  const cookieParts = ('; ' + document.cookie).split('; hubspotutk=');
  const payload = {
    submittedAt: Date.now(),
    fields: [
      {
        name: 'email',
        value: email,
      },
    ],
    context: {
      hutk: cookieParts.length === 2 ? cookieParts.pop()?.split(';').shift() : '',
      pageUri: document.location.href,
      pageName: document.title,
    },
  };

  return core
    .post<IHubspotFinishLaterResponse>(config.hubspotApi! + config.hubspotFinishLaterUUid, payload)
    .then(({data}) => data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const sendFinishLaterEmail = (email: string, step: string): Promise<any> =>
  core.post('/api/2/po/finish-later', queryString.stringify({email, step})).then(({data}) => data);
