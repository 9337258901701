import {FC} from 'react';

import * as Styled from './styled';

interface Props {
  icon?: any;
  fill?: string;
  boxW?: number | string;
  boxH?: number | string;
  onClick?: () => void;
  className?: string;
}

const BaseIcon: FC<Props> = ({fill, icon: IconComponent, boxW = 24, boxH = 24, ...other}) => (
  <Styled.Wrapper {...other}>
    {IconComponent && <IconComponent fill={fill} viewBox={`0 0 ${boxH} ${boxW}`} />}
  </Styled.Wrapper>
);

export default BaseIcon;
